import styled from 'styled-components';
import { Container, H3 } from '@tuunetech/tuune-components';

export const SidebarContainer = styled(Container)`
  margin-top: 45px;

  ${H3} {
    margin-bottom: 11px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 5%;
    margin-top: 89px;

    ${H3} {
      margin-bottom: 46px;
    }
  }
`;
