import React, { useEffect, useState } from 'react';
import Prismic from '@prismicio/client';
import { PageProps } from 'gatsby';
import { useSearch } from 'hooks';
import { RestPost } from 'types';
import { BlogSearchLayout } from 'components';
import { Layout } from 'components/shared';

const BlogSearch: React.FC<PageProps> = ({ location }) => {
  const { searchQuery } = useSearch();

  const [posts, setPosts] = useState<RestPost[]>([]);

  useEffect(() => {
    async function fetchFilteredPosts() {
      const client = Prismic.client(
        process.env.GATSBY_PRISMIC_REPO_NAME as string,
        {
          accessToken: process.env.GATSBY_PRISMIC_TOKEN,
        },
      );
      const posts: { [key: string]: RestPost } = {};
      const byTitle = await client.query(
        [
          Prismic.Predicates.at('document.type', 'blog_post'),
          Prismic.Predicates.fulltext('my.blog_post.title', searchQuery),
        ],
        {
          fetchLinks: ['category.uid', 'category.name'],
        },
      );

      byTitle.results.forEach(
        result => (posts[result.id] = result as RestPost),
      );

      const byCategories = await client.query([
        Prismic.Predicates.at('document.type', 'category'),
        Prismic.Predicates.fulltext('document', searchQuery),
      ]);

      if (byCategories.results.length) {
        const queries = byCategories.results.map(async result => {
          return client.query(
            [
              Prismic.Predicates.at('document.type', 'blog_post'),
              Prismic.Predicates.at('my.blog_post.category', result.id),
            ],
            {
              fetchLinks: ['category.uid', 'category.name'],
            },
          );
        });

        const byTagsPosts = await Promise.all(queries);
        byTagsPosts.forEach(({ results }) => {
          results.forEach(post => (posts[post.id] = post as RestPost));
        });
      }

      // const byKeywords = await client.query(
      //   [
      //     Prismic.Predicates.at('document.type', 'blog_post'),
      //     Prismic.Predicates.at('my.blog_post.keywords.keyword', searchQuery),
      //   ],
      //   {
      //     fetchLinks: ['tag.uid', 'tag.tag_visible_name'],
      //   },
      // );
      //
      // byKeywords.results.forEach(
      //   result => (posts[result.id] = result as RestPost),
      // );

      setPosts(Object.values(posts));
    }

    fetchFilteredPosts();
  }, [searchQuery]);

  return (
    <Layout path={location.pathname}>
      <BlogSearchLayout posts={posts} search={searchQuery} />
    </Layout>
  );
};

export default BlogSearch;
